export const header = {
  accountActions: {
    copyAddress: {
      title: 'Copy address'
    },
    viewOn: 'View on',
    connectAnotherAccount: {
      title: 'Connect another account'
    },
    disconnect: {
      title: 'Disconnect'
    }
  }
};
