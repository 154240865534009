import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';

import { Payment } from '@tezospayments/common';

import { ExternalLink } from '../../../../common';
import { useCurrentLanguageResources, useTezosPayments } from '../../../../hooks';

import './DirectLinkGenerator.scss';

interface DirectLinkGeneratorProps {
  payment: Payment;
  serviceAddress: string | undefined;
}

export const DirectLinkGenerator = ({ payment, serviceAddress }: DirectLinkGeneratorProps) => {
  const langResources = useCurrentLanguageResources();
  const commonLangResources = langResources.common;
  const acceptPaymentsLangResources = langResources.views.acceptPayments;
  const tezosPayments = useTezosPayments(serviceAddress);

  const [url, setUrl] = useState('');

  const getPaymentLink = useCallback(
    async () => {
      if (!tezosPayments)
        return '';

      try {
        const result = await tezosPayments.createPayment({
          id: payment.id,
          amount: payment.amount.toString(),
          asset: payment.asset,
          created: Date.now(),
          data: payment.data,
        });

        return result.url;
      }
      catch (e) {
        console.error(e);
        return '';
      }
    }, [tezosPayments, payment]
  );

  useEffect(() => {
    setUrl('');
  }, [getPaymentLink]);

  const handleGenerateAndSignClick = useCallback(() => {
    (async () => {
      setUrl(await getPaymentLink());
    })();
  }, [getPaymentLink]);

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  return <div className="generator__direct-link">
    {url && <span className="generator__direct-link-help-text">{acceptPaymentsLangResources.directLinkPaymentHelpText}</span>}
    <ExternalLink className="generator__direct-link-link" href={url}>{url}</ExternalLink>
    <div className="generator__direct-link-buttons">
      {!url && <Button type="primary" onClick={handleGenerateAndSignClick}>{acceptPaymentsLangResources.generateAndSignPaymentLink}</Button>}
      {url && <Button onClick={handleCopyClick} icon={<CopyOutlined />}>{commonLangResources.copyLink}</Button>}
    </div>
  </div >;
};

export const DirectLinkGeneratorPure = React.memo(DirectLinkGenerator);
