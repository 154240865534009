import { Card } from 'antd';
import BigNumber from 'bignumber.js';
import React from 'react';

import { Payment } from '@tezospayments/common';

import { selectTokensState } from '../../../../store/services/selectors';
import { useAppSelector, useTezosPayments } from '../../../hooks';
import { DirectLinkGeneratorPure } from './DirectLinkGenerator';
import { FailedValidationResult } from './FailedValidationResult';
import './Generator.scss';

interface GeneratorProps {
  serviceAddress: string | undefined;
  assetAddress: string | undefined;
  amount: string;
  paymentId: string;
  clientData: Record<string, unknown> | undefined;
}

export const Generator = (props: GeneratorProps) => {
  const tokens = useAppSelector(selectTokensState);
  const tezosPayments = useTezosPayments(props.serviceAddress);
  const currentAssetToken = props.assetAddress ? tokens.get(props.assetAddress) : undefined;

  const tabList = [
    { key: 'widget', tab: 'Widget', disabled: true },
    { key: 'typescript', tab: 'TypeScript Library', disabled: true },
    { key: 'dotnet', tab: '.NET Library', disabled: true },
  ] as Array<{ key: string, tab: string, disabled?: boolean }>;

  if (tezosPayments) {
    tabList.splice(0, 0, { key: 'directLink', tab: 'Direct Link' });
  }

  const payment: Payment = {
    targetAddress: props.serviceAddress || '',
    id: props.paymentId,
    asset: currentAssetToken ? {
      address: currentAssetToken.contractAddress,
      decimals: currentAssetToken.metadata?.decimals || 0,
      id: currentAssetToken.type === 'fa2' ? currentAssetToken.id : null
    } : undefined,
    amount: new BigNumber(props.amount),
    created: new Date(),
    signature: {
      signingPublicKey: 'edpkuWJYhY5TVZ6xaoubNkidXArJmVhjA2yuZZuucEN6FF4fQeV9Dd',
      contract: 'edsigtaNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNoneNog2Ndso',
    }
  };

  const failedValidationResults = Payment.validate(payment);

  return <Card
    className="generator"
    style={{ width: '100%' }}
    tabList={tabList}
    activeTabKey={'directLink'}
  >
    {failedValidationResults
      ? <FailedValidationResult results={failedValidationResults} />
      : <DirectLinkGeneratorPure payment={payment} serviceAddress={props.serviceAddress} />}
  </Card >;
};

export const GeneratorPure = React.memo(Generator);
