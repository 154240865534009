export const services = {
  title: 'Services',
  status: {
    active: 'Active',
    paused: 'Paused',
    deleted: 'Deleted',
    updating: 'Updating'
  },
  createNewService: 'Create New Service',
  acceptPayments: 'Accept Payments',
  acceptPaymentsDescription: 'Generate a payment link using our constructor to accept payments on your service',
  youDoNotHaveAnyServicesYet: 'You don\'t have any services yet',
  createYourFirstService: 'Create your first service to accept payments or connect another Tezos account',
  operations: {
    paymentsEnabled: 'Payments Enabled',
    donationsEnabled: 'Donations Enabled',
  },
  allowedCurrencies: 'Allowed Currencies',
  service: 'Service',
  links: 'Links',
  dangerZone: {
    title: 'Danger Zone',
    pauseService: 'Pause Service',
    pauseServiceConfirmation: 'Are you sure you want to pause the service',
    pauseServiceDescription: 'Pause accepting payments and donations',
    unPauseService: 'Unpause Service',
    unPauseServiceConfirmation: 'Are you sure you want to unpause the service',
    unPauseServiceDescription: 'Accepting payments and donations are paused',
    deleteService: 'Delete Service',
    deleteServiceConfirmation: 'Are you sure you want to delete the service',
    deleteServiceDescription: 'Delete this service. After deleting this service can’t accept payments and donations. You can restore the deleted service',
    unDeleteService: 'Undelete Service',
    unDeleteServiceConfirmation: 'Are you sure you want to undelete the service',
    unDeleteServiceDescription: 'Service is marked as deleted',
  },
  devZone: {
    title: 'API Keys',
    addKey: 'Add Key',
    saveKeys: 'Save Keys',
    generateKeys: 'Generate Keys',
    name: 'Name',
    algorithm: 'Algorithm',
    publicKey: 'Public Key',
    secretKey: 'Secret Key',
    saveSecretKeyWarning: 'Write down the secret key, we will never show it again',
    saveSecretKeyConfirm: 'Have you written down the secret key?',
    noApiKeysAddedYet: 'No API keys added yet',
    deleteKeyConfirmation: 'Are you sure you want to delete the API key',
  },
  editing: {
    editService: 'Edit Service',
    updateService: 'Update Service',
    createService: 'Create Service',
    addCurrency: 'Add Currency',
    addLink: 'Add Link',
    serviceName: 'Service Name',
    description: 'Description',
    accept: 'Accept',
    acceptPayments: 'Accept Payments',
    acceptDonations: 'Accept Donations',
  }
};
